<template>
    <div>
      <!-- 頂部區域 -->
      <div style="display: flex; align-items: center; justify-content: space-between; width: 120%; background-color: seashell; padding: 10px; box-sizing: border-box; overflow-x: hidden;">
        <h3 style="margin: 0;">在學期間公費醫學生人數</h3>
        <button id="exportBtn" class="btn btn-primary" @click="exportpage('points')">
            資料匯出
        </button>
      </div>
      <hr style="margin-top: 10px; margin-bottom: 0;" />
      
      <!-- 其他區域內容 -->
      <div class="row" style="display:block;width:120%;height: auto;background-color:seashell;">
        <div class="col-sm-12">
          <div id="chartpoints" style="height: 400px; width: 1200px;"></div>
        </div>
      </div>
      <hr />
  
      <div style="display: flex; align-items: center; justify-content: space-between; width: 120%; background-color: seashell; padding: 10px; box-sizing: border-box; overflow-x: hidden;">
        <h3 style="margin: 0;">訓練期間公費醫學生人數</h3>
        <button id="exporttrain" class="btn btn-primary" @click="exportpage('training')">
            資料匯出
        </button>
      </div>
      <hr style="margin-top: 10px; margin-bottom: 0;" />

      <div class="row" style="display:block;width:120%;height: auto;background-color:seashell;">
        <div class="col-sm-12">
          <div id="chartTraining" style="height: 400px; width: 1200px;"></div>
        </div>
      </div>
      <hr />
  
      <div style="display: flex; align-items: center; justify-content: space-between; width: 120%; background-color: seashell; padding: 10px; box-sizing: border-box; overflow-x: hidden;">
        <h3 style="margin: 0;">服務期間公費醫學生人數</h3>
        <button id="exportservice" class="btn btn-primary" @click="exportpage('service')">
            資料匯出
        </button>
      </div>
      <hr style="margin-top: 10px; margin-bottom: 0;" />

      <div class="row" style="display:block;width:120%;height: auto;background-color:seashell;">
        <div class="col-sm-12">
          <div id="chartService" style="height: 400px; width: 1200px;"></div>
        </div>
      </div>
      
      <div v-if="isModalVisible" class="modal fade show d-block" style="overflow-y: auto;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <!-- 標題部分 -->
                <div class="modal-header">
                    <h5 class="modal-title" id="exportModalLabel">公費醫學生人數</h5>
                    <!-- 添加關閉按鈕 -->
                    <button type="button" 
                            class="btn-close" 
                            @click="closeModal" 
                            aria-label="Close"
                            style="background-color: #f5f5f5; border-radius: 50%; width: 32px; height: 32px; display: flex; align-items: center; justify-content: center; border: none; font-size: 20px; color: #000; cursor: pointer;">
                    ✖
                    </button>
                </div>
                
                <!-- 滾動內容 -->
                <div>
                    <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
                    <table class="styled-table table table-bordered">
                        <thead>
                        <tr>
                            <th class="county-header">縣市</th>
                            <th class="school-header">學校</th>
                            <th class="number-header">人數</th>
                        </tr>
                        </thead>
                        <tbody>
                        <!-- 使用 v-for 動態生成表格 -->
                        <template v-for="(group, groupIndex) in groupedData">
                            <!-- 將 key 移至第一個 <tr> -->
                            <tr v-for="(school, schoolIndex) in group.schools" :key="`${groupIndex}-${schoolIndex}`">
                            <!-- 第一列合併縣市的行 -->
                            <td v-if="schoolIndex === 0" :rowspan="group.schools.length" class="county">
                                {{ group.network }}
                            </td>
                            <td class="school">{{ school.name }}</td>
                            <td class="number">{{ school.number }}</td>
                            </tr>
                        </template>
                        <!-- 總計行 -->
                        <tr>
                            <td colspan="2" class="total-header">總計</td>
                            <td class="total-number">{{ totalNumber }}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                
                <!-- 底部按鈕 -->
                <div class="modal-footer">
                    <button 
                        type="button" 
                        style="
                            background-color:crimson; 
                            color: white; 
                            border: 1px solid crimson; 
                            border-radius: 10px; 
                            padding: 5px 10px; 
                            cursor: pointer;
                            transition: background-color 0.3s ease, transform 0.2s ease;"
                        @click="closeModal">
                        關閉
                    </button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>


<script>
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import {
        domainObject,
    } from "@/constant.js";

    export default {
    data() {
        return {
        counties: [
            { value: "100", name: "臺北市" },
            { value: "207", name: "新北市" },
            { value: "200", name: "基隆市" },
            { value: "260", name: "宜蘭縣" },
            { value: "320", name: "桃園市" },
            { value: "300", name: "新竹市" },
            { value: "302", name: "新竹縣" },
            { value: "350", name: "苗栗縣" },
            { value: "400", name: "臺中市" },
            { value: "500", name: "彰化縣" },
            { value: "540", name: "南投縣" },
            { value: "600", name: "嘉義市" },
            { value: "602", name: "嘉義縣" },
            { value: "630", name: "雲林縣" },
            { value: "700", name: "臺南市" },
            { value: "800", name: "高雄市" },
            { value: "880", name: "澎湖縣" },
            { value: "900", name: "屏東縣" },
            { value: "950", name: "臺東縣" },
            { value: "970", name: "花蓮縣" },
            { value: "890", name: "金門縣" },
            { value: "209", name: "連江縣" },
            { value: "929", name: "琉球鄉" },
            { value: "330", name: "桃園縣" },
            { value: "971", name: "花蓮市" },
            { value: "95", name: "綠島鄉" },
            { value: "952", name: "蘭嶼鄉" },
            { value: "310", name: "新竹縣" },
        ],
        pointsdata: [],
        trainingdata: [],
        servicedata: [],
        years: Array.from({ length: 21 }, (_, i) => 90 + i), // 90 至 110 年
        selectedpointsCounty: "",
        selectedTraining: "",
        selectedService: "",
        panel1Data: "請選擇縣市後查看資料。",
        panel2Data: "請選擇縣市和年度範圍後查看資料。",
        panel3Data: "請選擇縣市後查看資料。",
        MyGlobalObject: {},  
        isModalVisible: false, // 控制模態框顯示
        domainObject,
        };
    },
    name: "RptByCounty", 
    mounted() {
        this.getDatapoints();
        this.getDataTraining();
        this.getDataService();
    },
    methods: {
        async exportpage(status) {
            // 組裝匯出 URL
            let urlexl = ''; // 将 const 改为 let
            let data; // 同时声明 data 变量
            console.log(status);
            if(status == 'points'){
                urlexl = `RptChart/exportPointsList`;
                data = this.pointsdata;
            }else if(status == 'training'){
                urlexl = `RptChart/exporttrainingList`;
                data = this.trainingdata;
            }else if(status == 'service'){
                urlexl = `RptChart/exportserviceList`;
                data = this.servicedata;
            }
            try {
                const res = await window.axios.post(urlexl, data, {
                    headers: {
                        "Content-Type": "application/json", // 确保数据以 JSON 格式发送
                    },
                });
                const excelUrl = `${this.domainObject.platformOriginal}${res}`;
                window.open(excelUrl);
            } catch (error) {
                // 顯示錯誤信息
                this.$bvToast.toast(error.message || "匯出失敗", {
                title: "系統資訊",
                autoHideDelay: 5000,
                variant: "danger",
                });
            }
        },
        async getDatapoints() {
            try {
                const response = await axios.get(`/RptChart/GetPointsCountyList`);
                this.Data = response.CustomModel; 
                console.log(this.Data);
                this.pointsdata = response.GroupedData; 
                this.DatapointsChart(this.Data);
            } catch (error) {
                console.error("Error fetching country data:", error);
                alert("資料載入失敗，請稍後再試");
            }
        },
        DatapointsChart(Data) {
            const self = this;
            am5.ready(function () {
                if (self.pointChartRoot) {
                    self.pointChartRoot.dispose();
                }

                const root9 = am5.Root.new("chartpoints");
                self.pointChartRoot = root9;

                root9.setThemes([am5themes_Animated.new(root9)]);

                const mainContainer = root9.container.children.push(
                    am5.Container.new(root9, {
                        layout: root9.horizontalLayout,
                        width: am5.p100,
                        height: am5.p100,
                    })
                );

                const chartContainer = mainContainer.children.push(
                    am5.Container.new(root9, {
                        width: am5.percent(80),
                        height: am5.p100,
                    })
                );

                const chart = chartContainer.children.push(
                    am5xy.XYChart.new(root9, {
                        panX: false,
                        panY: false,
                        wheelX: "none",
                        wheelY: "none",
                    })
                );

                chart.get("colors").set("colors", [
                    am5.color(0x1d728c),
                ]);
                chart.zoomOutButton.set("forceHidden", true);

                const yRenderer = am5xy.AxisRendererY.new(root9, { minGridDistance: 20 });
                yRenderer.grid.template.set("location", 1);

                const yAxis = chart.yAxes.push(
                    am5xy.CategoryAxis.new(root9, {
                        maxDeviation: 0,
                        categoryField: "network", // 绑定分类字段
                        renderer: yRenderer,
                        tooltip: am5.Tooltip.new(root9, { themeTags: ["axis"] }),
                    })
                );

                const xAxis = chart.xAxes.push(
                    am5xy.ValueAxis.new(root9, {
                        maxDeviation: 0,
                        min: 0,
                        extraMax: 0.1,
                        renderer: am5xy.AxisRendererX.new(root9, { strokeOpacity: 0.1 }),
                    })
                );

                // 创建单个 Series 显示总数
                const series = chart.series.push(
                    am5xy.ColumnSeries.new(root9, {
                        name: "人數",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueXField: "total", // 绑定值字段
                        categoryYField: "network", // 绑定分类字段
                        tooltip: am5.Tooltip.new(root9, {
                            labelText: "{valueX}",
                        }),
                    })
                );

                series.columns.template.setAll({
                    tooltipText: "{valueX}", 
                    tooltipY: am5.percent(10),
                });

                series.columns.template.adapters.add("fill", (fill, target) => {
                    return chart.get("colors").getIndex(0); // 设置颜色
                });

                series.columns.template.adapters.add("stroke", (stroke, target) => {
                    return chart.get("colors").getIndex(0); // 设置颜色
                });

                // 设置数据
                yAxis.data.setAll(Data);
                series.data.setAll(Data);

                const legendContainer = mainContainer.children.push(
                    am5.Container.new(root9, {
                        width: am5.percent(20),
                        height: am5.p100,
                        layout: root9.verticalLayout,
                    })
                );

                const legend = legendContainer.children.push(
                    am5.Legend.new(root9, {
                        centerY: am5.p50,
                        y: am5.p50,
                        layout: root9.verticalLayout,
                    })
                );
                legend.data.setAll(chart.series.values);
                series.appear(1000);
                chart.appear(1000, 100);
                root9._logo.dispose();
            });
        },
        async getDataTraining() {
            try {
                const response = await axios.get(`/RptChart/GetTrainingList`);

                this.Data = response; //資料集為network,total,value1,value2,value3,value4,value4,value5,value6
                this.trainingdata = response; 
                console.log(this.trainingdata);
                this.DataTrainingChart(this.Data);
            } catch (error) {
                console.error("Error fetching country data:", error);
                alert("資料載入失敗，請稍後再試");
            }
        },
        async getDataService() {
            try {
                const response = await axios.get(`/RptChart/GetServiceList`);

                this.Data = response; 
                this.servicedata = response; 
                this.DataServiceChart(this.Data);
            } catch (error) {
                console.error("Error fetching country data:", error);
                alert("資料載入失敗，請稍後再試");
            }
        },
        DataTrainingChart(Data) {
          const self = this;
          am5.ready(function () {
              // 清理舊圖表實例
              if (self.trainingChartRoot) {
                  self.trainingChartRoot.dispose();
              }
              const root9 = am5.Root.new("chartTraining");
              self.trainingChartRoot = root9;

              root9.setThemes([am5themes_Animated.new(root9)]);

              const mainContainer = root9.container.children.push(
                  am5.Container.new(root9, {
                      layout: root9.horizontalLayout,
                      width: am5.p100,
                      height: am5.p100,
                  })
              );

              const chartContainer = mainContainer.children.push(
                  am5.Container.new(root9, {
                      width: am5.percent(80),
                      height: am5.p100,
                  })
              );

              const chart = chartContainer.children.push(
                  am5xy.XYChart.new(root9, {
                      panX: false,
                      panY: false,
                      wheelX: "none",
                      wheelY: "none",
                  })
              );

              chart.get("colors").set("colors", [
                  am5.color(0x1d728c),
                  am5.color(0x29a19c),
                  am5.color(0xf5a623),
                  am5.color(0xff6f61),
                  am5.color(0x9c27b0),
                  am5.color(0x000000),
              ]);
              chart.zoomOutButton.set("forceHidden", true);

              const yRenderer = am5xy.AxisRendererY.new(root9, { minGridDistance: 20 });
              yRenderer.grid.template.set("location", 1);

              const yAxis = chart.yAxes.push(
                  am5xy.CategoryAxis.new(root9, {
                      maxDeviation: 0,
                      categoryField: "network",
                      renderer: yRenderer,
                      tooltip: am5.Tooltip.new(root9, { themeTags: ["axis"] }),
                  })
              );

              const xAxis = chart.xAxes.push(
                  am5xy.ValueAxis.new(root9, {
                      maxDeviation: 0,
                      min: 0,
                      extraMax: 0.1,
                      renderer: am5xy.AxisRendererX.new(root9, { strokeOpacity: 0.1 }),
                  })
              );

              const serviceItemLabels = {
                  "內科": "內科",
                  "外科": "外科",
                  "婦產科": "婦產科",
                  "兒科": "兒科",
                  "急診醫學科": "急診醫學科",
                  "不分科": "不分科",
              };

              const seriesFields = ["value1", "value2", "value3", "value4", "value5", "value6"];
              seriesFields.forEach((field, index) => {
                  const serviceItem = Object.keys(serviceItemLabels)[index];
                  const series = chart.series.push(
                      am5xy.ColumnSeries.new(root9, {
                          name: serviceItemLabels[serviceItem] || `Value ${index + 1}`,
                          xAxis: xAxis,
                          yAxis: yAxis,
                          valueXField: field, 
                          categoryYField: "network", 
                          stacked: true,
                      })
                  );
                  series.columns.template.setAll({
                      tooltipText: "{valueX}", 
                      tooltipY: am5.percent(10), 
                  });
                  series.columns.template.events.on("pointerover", function (ev) {
                      const column = ev.target;
                      const dataItem = column.dataItem; 
                      if (dataItem) {
                          const valueX = dataItem.get("valueX");
                          console.log("Actual valueX data:", valueX);
                      } else {
                          console.warn("No dataItem found for column:", column);
                      }
                  });
                  series.columns.template.adapters.add("fill", (fill, target) => {
                      return chart.get("colors").getIndex(index);
                  });
                  series.columns.template.adapters.add("stroke", (stroke, target) => {
                      return chart.get("colors").getIndex(index);
                  });
              });
              
              yAxis.data.setAll(Data);
              chart.series.values.forEach((series) => {
                  series.data.setAll(Data);
              });

              const legendContainer = mainContainer.children.push(
                  am5.Container.new(root9, {
                      width: am5.percent(20),
                      height: am5.p100,
                      layout: root9.verticalLayout,
                  })
              );

              const legend = legendContainer.children.push(
                  am5.Legend.new(root9, {
                      centerY: am5.p50,
                      y: am5.p50,
                      layout: root9.verticalLayout,
                  })
              );

              legend.data.setAll(chart.series.values);

              chart.series.values.forEach((series) => {
                  series.appear(1000);
              });
              chart.appear(1000, 100);
              root9._logo.dispose();
          });
        },
        DataServiceChart(Data) {
          const self = this;
          am5.ready(function () {
              // 清理舊圖表實例
              if (self.serviceChartRoot) {
                  self.serviceChartRoot.dispose();
              }

              const root9 = am5.Root.new("chartService");
              self.serviceChartRoot = root9;

              root9.setThemes([am5themes_Animated.new(root9)]);

              const mainContainer = root9.container.children.push(
                  am5.Container.new(root9, {
                      layout: root9.horizontalLayout,
                      width: am5.p100,
                      height: am5.p100,
                  })
              );

              const chartContainer = mainContainer.children.push(
                  am5.Container.new(root9, {
                      width: am5.percent(80),
                      height: am5.p100,
                  })
              );

              const chart = chartContainer.children.push(
                  am5xy.XYChart.new(root9, {
                      panX: false,
                      panY: false,
                      wheelX: "none",
                      wheelY: "none",
                  })
              );

              chart.get("colors").set("colors", [
                  am5.color(0x1d728c),
                  am5.color(0x29a19c),
                  am5.color(0xf5a623),
                  am5.color(0xff6f61),
                  am5.color(0x9c27b0),
                  am5.color(0x000000),
              ]);
              chart.zoomOutButton.set("forceHidden", true);

              const yRenderer = am5xy.AxisRendererY.new(root9, { minGridDistance: 20 });
              yRenderer.grid.template.set("location", 1);

              const yAxis = chart.yAxes.push(
                  am5xy.CategoryAxis.new(root9, {
                      maxDeviation: 0,
                      categoryField: "network",
                      renderer: yRenderer,
                      tooltip: am5.Tooltip.new(root9, { themeTags: ["axis"] }),
                  })
              );

              const xAxis = chart.xAxes.push(
                  am5xy.ValueAxis.new(root9, {
                      maxDeviation: 0,
                      min: 0,
                      extraMax: 0.1,
                      renderer: am5xy.AxisRendererX.new(root9, { strokeOpacity: 0.1 }),
                  })
              );

              const serviceItemLabels = {
                  "內科": "內科",
                  "外科": "外科",
                  "婦產科": "婦產科",
                  "兒科": "兒科",
                  "急診醫學科": "急診醫學科",
                  "不分科": "不分科",
              };

              const seriesFields = ["value1", "value2", "value3", "value4", "value5", "value6"];
              seriesFields.forEach((field, index) => {
                  const serviceItem = Object.keys(serviceItemLabels)[index];
                  const series = chart.series.push(
                      am5xy.ColumnSeries.new(root9, {
                          name: serviceItemLabels[serviceItem] || `Value ${index + 1}`,
                          xAxis: xAxis,
                          yAxis: yAxis,
                          valueXField: field, 
                          categoryYField: "network", 
                          stacked: true,
                      })
                  );
                  series.columns.template.setAll({
                      tooltipText: "{valueX}", 
                      tooltipY: am5.percent(10), 
                  });
                  series.columns.template.events.on("pointerover", function (ev) {
                      const column = ev.target;
                      const dataItem = column.dataItem; 
                      if (dataItem) {
                          const valueX = dataItem.get("valueX");
                          console.log("Actual valueX data:", valueX);
                      } else {
                          console.warn("No dataItem found for column:", column);
                      }
                  });
                  series.columns.template.adapters.add("fill", (fill, target) => {
                      return chart.get("colors").getIndex(index);
                  });
                  series.columns.template.adapters.add("stroke", (stroke, target) => {
                      return chart.get("colors").getIndex(index);
                  });
              });
              
              yAxis.data.setAll(Data);
              chart.series.values.forEach((series) => {
                  series.data.setAll(Data);
              });

              const legendContainer = mainContainer.children.push(
                  am5.Container.new(root9, {
                      width: am5.percent(20),
                      height: am5.p100,
                      layout: root9.verticalLayout,
                  })
              );

              const legend = legendContainer.children.push(
                  am5.Legend.new(root9, {
                      centerY: am5.p50,
                      y: am5.p50,
                      layout: root9.verticalLayout,
                  })
              );

              legend.data.setAll(chart.series.values);

              chart.series.values.forEach((series) => {
                  series.appear(1000);
              });
              chart.appear(1000, 100);
              root9._logo.dispose();
          });
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
    },
    computed: {
        // 將資料根據縣市分組
        groupedData() {
            const grouped = {};
            this.countiesdata.forEach((item) => {
                // 按照原字段名修改
                if (!grouped[item.network]) {
                    grouped[item.network] = []; // network 为县市字段
                }
                grouped[item.network].push({ name: item.school, number: item.total });
            });
            return Object.keys(grouped).map((network) => ({
                network, // 縣市名稱
                schools: grouped[network], // 學校和人數
            }));
        },
        // 计算所有数据的总人数
        totalNumber() {
            return this.countiesdata.reduce((sum, item) => sum + item.total, 0);
        },
    },
};
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5); /* 添加背景遮罩 */
}

.styled-table th {
  text-align: center;
  font-weight: bold;
}

.county-header {
  background-color: orange;
  color: black;
}

.school-header {
  background-color: lightgreen;
  color: black;
}

.number-header {
  background-color: lightblue;
  color: black;
}

/* 資料列樣式 */
.styled-table td {
  text-align: center;
}

.county {
  background-color: orange;
  color: black;
  /*font-weight: bold;*/
  text-align: center;
  vertical-align: middle; /* 垂直居中 */
}

.school {
  background-color: lightgreen;
  color: black;
}

.number {
  background-color: lightblue;
  color: black;
}

/* 總計列樣式 */
.total-header {
  background-color: #4682B4;
  color: black;
  text-align: right;
}

.total-number {
  background-color: lightblue;
  color: black;
}

</style>