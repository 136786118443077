<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName">
      <v-tab
        v-for="(item, i) in allUserRight"
        :title="item.text"
        :key="item + i"
      >
        <rptByCounty v-if="item.text == '在地養成報表-依縣市別'" ></rptByCounty>
        <rptByCategory v-if="item.text == '在地養成報表-依籍屬別'" ></rptByCategory>
        <rptByCounty v-if="item.text == '招生統計'"></rptByCounty>
        <rptByCounty2 v-if="item.text == '重點科別'"></rptByCounty2>
      </v-tab>
    </vue-tabs>
         
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import rptByCounty from "./rptByCounty";
import rptByCategory from "./rptByCategory";
import rptByCounty2 from "./rptByCounty2";

export default {
  name: "LocalCourseManageTab",
  data() {
    return {
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [
        { text: "在地養成報表-依縣市別", isActive: false, functionId: "99901" },
        { text: "在地養成報表-依籍屬別", isActive: false, functionId: "99902" },
        { text: "招生統計", isActive: false, functionId: "99903" },
        { text: "重點科別", isActive: false, functionId: "99904" },
      ],
      userRights: [],
      mainTitle: "「統計報表」",
      titles: [
      ],
    };
  },
  computed: {},
  components: {
    VueTabs,
    VTab,
    rptByCounty,
    rptByCategory,
    rptByCounty2,
  },
  methods: {
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.titles = this.titles.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.titles = this.titles.filter((s) => s.isActive);
    },
  },
  created() {},
  beforeMount() {},
  mounted() {
    //this.setUserRight();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
