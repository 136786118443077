<template>
    <div>
      <div style="display:block;width:120%;height: auto;background-color:seashell;">
        <h3>不同狀態(在學、訓練、服務等)之醫事人員數</h3>
        <span>
          <label for="optCounty" style="font-size:22px;font-weight: bold;">縣市</label>
          <select
            id="optCounty"
            v-model="selectedCounty1"
            @change="getDataInitialize"
            style="font-size:20px;margin-left:10px;width:120px;"
          >
            <option value="">全縣市</option>
            <option v-for="county in counties" :key="county.value" :value="county.value">
              {{ county.name }}
            </option>
          </select>
        </span>
        <hr />
        <div id="cPanel1">
          {{ panel1Data }}
        </div>
      </div>
      <div class="row" style="display:block;width:120%;height: auto;background-color:seashell;">
            <div class="col-sm-12">
                <div id="chartCaseResultAnalyze" style="height: 400px; width: 1200px;"></div>
            </div>
        </div>
      <hr />
  
      <div style="display:block;width:120%;height:auto;background-color:ivory;">
        <h3>服務期滿留任率</h3>
        <span>
          <label for="optYearCounty" style="font-size:22px;font-weight: bold;">縣市</label>
          <select
            id="optYearCounty"
            v-model="selectedCounty2"
            @change="getDataRetentionRate"
            style="font-size:20px;margin-left:10px;width:120px;"
          >
            <option value="">全縣市</option>
            <option v-for="county in counties" :key="county.value" :value="county.value">
              {{ county.name }}
            </option>
          </select>
  
          <label for="optBeginYearCounty" style="font-size:22px;font-weight: bold; margin-left:50px;">年度</label>
          <select
            id="optBeginYearCounty"
            v-model="selectedBeginYear"
            @change="getDataRetentionRate"
            style="font-size:20px;margin-left:10px;width:120px;"
          >
            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
          </select>
  
          <label for="optEndYearCounty" style="font-size:22px;font-weight: bold;">～</label>
          <select
            id="optEndYearCounty"
            v-model="selectedEndYear"
            @change="getDataRetentionRate"
            style="font-size:20px;margin-left:10px;width:120px;"
          >
            <option v-for="year in years" :key="'end-' + year" :value="year">{{ year }}</option>
          </select>
        </span>
        <hr />
        <div id="cPanel2">
          {{ panel2Data }}
        </div>
      </div>
      <div class="row" style="display:block;width:120%;height: auto;background-color:ivory;">
            <div class="col-sm-12">
                <div id="ServiceRetentionRate" style="height: 400px; width: 1200px;"></div>
            </div>
        </div>
      <hr />
  
      <div style="display:block;width:120%;height:auto;background-color:seashell;">
        <h3>不同狀態(在學、訓練、服務等)之專科醫師數</h3>
        <span>
          <label for="optStatusCounty" style="font-size:22px;font-weight: bold;">縣市</label>
          <select
            id="optStatusCounty"
            v-model="selectedCounty3"
            @change="getDataCardiologists"
            style="font-size:20px;margin-left:10px;width:120px;"
          >
            <option value="">全縣市</option>
            <option v-for="county in counties" :key="county.value" :value="county.value">
              {{ county.name }}
            </option>
          </select>
        </span>
        <hr />
        <div id="cPanel3">
          {{ panel3Data }}
        </div>
      </div>
      <div class="row" style="display:block;width:120%;height: auto;background-color:seashell;">
            <div class="col-sm-12">
                <div id="CardiologistsNumber" style="height: 400px; width: 1200px;"></div>
            </div>
        </div>
    </div>
  </template>


<script>
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

    export default {
    data() {
        return {
        counties: [
            { value: "100", name: "臺北市" },
            { value: "207", name: "新北市" },
            { value: "200", name: "基隆市" },
            { value: "260", name: "宜蘭縣" },
            { value: "320", name: "桃園市" },
            { value: "300", name: "新竹市" },
            { value: "302", name: "新竹縣" },
            { value: "350", name: "苗栗縣" },
            { value: "400", name: "臺中市" },
            { value: "500", name: "彰化縣" },
            { value: "540", name: "南投縣" },
            { value: "600", name: "嘉義市" },
            { value: "602", name: "嘉義縣" },
            { value: "630", name: "雲林縣" },
            { value: "700", name: "臺南市" },
            { value: "800", name: "高雄市" },
            { value: "880", name: "澎湖縣" },
            { value: "900", name: "屏東縣" },
            { value: "950", name: "臺東縣" },
            { value: "970", name: "花蓮縣" },
            { value: "890", name: "金門縣" },
            { value: "209", name: "連江縣" },
            { value: "929", name: "琉球鄉" },
            { value: "330", name: "桃園縣" },
            { value: "971", name: "花蓮市" },
            { value: "95", name: "綠島鄉" },
            { value: "952", name: "蘭嶼鄉" },
            { value: "310", name: "新竹縣" },
        ],
        years: Array.from({ length: 21 }, (_, i) => 90 + i), // 90 至 110 年
        selectedCounty1: "",
        selectedCounty2: "",
        selectedBeginYear: 90,
        selectedEndYear: 110,
        selectedCounty3: "",
        panel1Data: "請選擇縣市後查看資料。",
        panel2Data: "請選擇縣市和年度範圍後查看資料。",
        panel3Data: "請選擇縣市後查看資料。",
        MyGlobalObject: {},  
        };
    },
    name: "RptByCounty", 
    mounted() {
      this.$nextTick(() => {
          //this.initializeChart();
          //this.ServiceRetentionRateChart();
          //this.CardiologistsNumberChart();
        });
    },
    methods: {
        getSeriesItem(category, series) {
            if (!series || !series.dataItems || series.dataItems.length === 0) {
                console.error("Series 或 dataItems 不存在或尚未初始化。");
                return null;
            }

            // 開始遍歷 series.dataItems
            for (let i = 0; i < series.dataItems.length; i++) {
                const dataItem = series.dataItems[i];
                if (dataItem.get("categoryY") === category) {
                    return dataItem;
                }
            }
            return null; // 如果找不到匹配的項目，返回 null
        },
        initializeChart(Data) {
          const self = this;
          am5.ready(function () {
              // 清理舊圖表實例
              if (self.stsChartRoot) {
                  self.stsChartRoot.dispose();
              }

              const root9 = am5.Root.new("chartCaseResultAnalyze");
              self.stsChartRoot = root9;

              root9.setThemes([am5themes_Animated.new(root9)]);

              const mainContainer = root9.container.children.push(
                  am5.Container.new(root9, {
                      layout: root9.horizontalLayout,
                      width: am5.p100,
                      height: am5.p100,
                  })
              );

              const chartContainer = mainContainer.children.push(
                  am5.Container.new(root9, {
                      width: am5.percent(80),
                      height: am5.p100,
                  })
              );

              const chart = chartContainer.children.push(
                  am5xy.XYChart.new(root9, {
                      panX: false,
                      panY: false,
                      wheelX: "none",
                      wheelY: "none",
                  })
              );

              chart.get("colors").set("colors", [
                  am5.color(0x1d728c),
                  am5.color(0x29a19c),
                  am5.color(0xf5a623),
                  am5.color(0xff6f61),
                  am5.color(0x9c27b0),
              ]);
              chart.zoomOutButton.set("forceHidden", true);

              const yRenderer = am5xy.AxisRendererY.new(root9, { minGridDistance: 20 });
              yRenderer.grid.template.set("location", 1);

              const yAxis = chart.yAxes.push(
                  am5xy.CategoryAxis.new(root9, {
                      maxDeviation: 0,
                      categoryField: "network",
                      renderer: yRenderer,
                      tooltip: am5.Tooltip.new(root9, { themeTags: ["axis"] }),
                  })
              );

              const xAxis = chart.xAxes.push(
                  am5xy.ValueAxis.new(root9, {
                      maxDeviation: 0,
                      min: 0,
                      extraMax: 0.1,
                      renderer: am5xy.AxisRendererX.new(root9, { strokeOpacity: 0.1 }),
                  })
              );

              const serviceItemLabels = {
                  "在學期間": "在學期間",
                  "訓練期間": "訓練期間",
                  "服務期間": "服務期間",
                  "服務期滿": "服務期滿",
                  "其他": "其他(履約終止、退學、畢業)",
              };

              const seriesFields = ["value1", "value2", "value3", "value4", "value5"];
              seriesFields.forEach((field, index) => {
                  const serviceItem = Object.keys(serviceItemLabels)[index];
                  const series = chart.series.push(
                      am5xy.ColumnSeries.new(root9, {
                          name: serviceItemLabels[serviceItem] || `Value ${index + 1}`,
                          xAxis: xAxis,
                          yAxis: yAxis,
                          valueXField: field, 
                          categoryYField: "network", 
                          stacked: true,
                      })
                  );
                  series.columns.template.setAll({
                      tooltipText: "{valueX}", 
                      tooltipY: am5.percent(10), 
                  });
                  series.columns.template.events.on("pointerover", function (ev) {
                      const column = ev.target;
                      const dataItem = column.dataItem; 
                      if (dataItem) {
                          const valueX = dataItem.get("valueX");
                          console.log("Actual valueX data:", valueX);
                      } else {
                          console.warn("No dataItem found for column:", column);
                      }
                  });
                  series.columns.template.adapters.add("fill", (fill, target) => {
                      return chart.get("colors").getIndex(index);
                  });
                  series.columns.template.adapters.add("stroke", (stroke, target) => {
                      return chart.get("colors").getIndex(index);
                  });
              });
              
              yAxis.data.setAll(Data);
              chart.series.values.forEach((series) => {
                  series.data.setAll(Data);
              });

              const legendContainer = mainContainer.children.push(
                  am5.Container.new(root9, {
                      width: am5.percent(20),
                      height: am5.p100,
                      layout: root9.verticalLayout,
                  })
              );

              const legend = legendContainer.children.push(
                  am5.Legend.new(root9, {
                      centerY: am5.p50,
                      y: am5.p50,
                      layout: root9.verticalLayout,
                  })
              );

              legend.data.setAll(chart.series.values);

              chart.series.values.forEach((series) => {
                  series.appear(1000);
              });
              chart.appear(1000, 100);
              root9._logo.dispose();
          });
        },
        ServiceRetentionRateChart(rateData) {
            const self = this;

            if (this.chartRoot) {
                this.chartRoot.dispose();
            }
            am5.ready(function () {
                var root9 = am5.Root.new("ServiceRetentionRate");
                self.chartRoot = root9;
                root9.setThemes([am5themes_Animated.new(root9)]);
                var mainContainer = root9.container.children.push(
                    am5.Container.new(root9, {
                        layout: root9.horizontalLayout,
                        width: am5.p100,
                        height: am5.p100,
                    })
                );
                var chartContainer = mainContainer.children.push(
                    am5.Container.new(root9, {
                        width: am5.percent(80),
                        height: am5.p100,
                    })
                );
                var chart = chartContainer.children.push(
                    am5xy.XYChart.new(root9, {
                        panX: false,
                        panY: false,
                        wheelX: "none",
                        wheelY: "none",
                    })
                );

                chart.zoomOutButton.set("forceHidden", true);
                var yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root9, {
                        renderer: am5xy.AxisRendererY.new(root9, {}),
                        min: 0,
                    })
                );
                var xAxis = chart.xAxes.push(
                    am5xy.CategoryAxis.new(root9, {
                        categoryField: "category", 
                        renderer: am5xy.AxisRendererX.new(root9, {
                            minGridDistance: 30, 
                            cellPadding: 0,      
                        }),
                        paddingRight: 0,
                    })
                );

                var data = rateData;
                xAxis.data.setAll(data);
                var columnSeries1 = chart.series.push(
                    am5xy.ColumnSeries.new(root9, {
                        name: "服務期滿人數",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "columnValue",
                        categoryXField: "category",
                        tooltip: am5.Tooltip.new(root9, {
                            labelText: "{category}: {valueY}",
                        }),
                        clustered: true,
                    })
                );
                columnSeries1.columns.template.setAll({
                    width: am5.percent(40), 
                    innerPaddingLeft: 0,  
                    innerPaddingRight: 0,    
                    fill: am5.color(0x1d728c),
                    stroke: am5.color(0x1d728c),
                    strokeOpacity: 0,
                    tooltipText: "{valueY}", 
                    tooltipY: am5.percent(10), 
                });
                columnSeries1.data.setAll(data);
                var columnSeries2 = chart.series.push(
                    am5xy.ColumnSeries.new(root9, {
                        name: "留任人數",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "columnValue2",
                        categoryXField: "category",
                        tooltip: am5.Tooltip.new(root9, {
                            labelText: "{category}: {valueY}",
                        }),
                        clustered: true, 
                    })
                );
                columnSeries2.columns.template.setAll({
                    width: am5.percent(40), 
                    innerPaddingLeft: 0,     
                    innerPaddingRight: 0,    
                    fill: am5.color(0xff7f0e),
                    stroke: am5.color(0xff7f0e),
                    strokeOpacity: 0,
                    tooltipText: "{valueY}", 
                    tooltipY: am5.percent(10), 
                });
                columnSeries2.columns.template.setAll({
                    x: am5.percent(60), 
                });
                columnSeries2.data.setAll(data);
                var lineSeries = chart.series.push(
                    am5xy.LineSeries.new(root9, {
                        name: "留任率",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "lineValue",
                        categoryXField: "category",
                        tooltip: am5.Tooltip.new(root9, {
                            labelText: "{category}: {valueY}",
                        }),
                    })
                );
                lineSeries.bullets.push(function () {
                    return am5.Bullet.new(root9, {
                        sprite: am5.Circle.new(root9, {
                            radius: 5,
                            fill: lineSeries.get("fill"),
                            tooltipText: "{valueY}%", 
                            tooltipY: am5.percent(10), 
                        }),
                    });
                });
                lineSeries.data.setAll(data);
                var legendContainer = mainContainer.children.push(
                    am5.Container.new(root9, {
                        width: am5.percent(20),
                        height: am5.p100,
                        layout: root9.verticalLayout,
                    })
                );
                var legend = legendContainer.children.push(
                    am5.Legend.new(root9, {
                        centerY: am5.p50,
                        y: am5.p50,
                        layout: root9.verticalLayout,
                    })
                );
                legend.data.setAll(chart.series.values);
                columnSeries1.appear(1000);
                columnSeries2.appear(1000);
                lineSeries.appear(1000);
                chart.appear(1000, 100);
                root9._logo.dispose();
            });
        },
        CardiologistsNumberChart(Data) {
          const self = this;
          am5.ready(function () {
              // 清理舊圖表實例
              if (self.numChartRoot) {
                  self.numChartRoot.dispose();
              }

              const root9 = am5.Root.new("CardiologistsNumber");
              self.numChartRoot = root9;

              root9.setThemes([am5themes_Animated.new(root9)]);

              const mainContainer = root9.container.children.push(
                  am5.Container.new(root9, {
                      layout: root9.horizontalLayout,
                      width: am5.p100,
                      height: am5.p100,
                  })
              );

              const chartContainer = mainContainer.children.push(
                  am5.Container.new(root9, {
                      width: am5.percent(80),
                      height: am5.p100,
                  })
              );

              const chart = chartContainer.children.push(
                  am5xy.XYChart.new(root9, {
                      panX: false,
                      panY: false,
                      wheelX: "none",
                      wheelY: "none",
                  })
              );

              chart.get("colors").set("colors", [
                  am5.color(0x1d728c),
                  am5.color(0x29a19c),
                  am5.color(0xf5a623),
              ]);
              chart.zoomOutButton.set("forceHidden", true);

              const yRenderer = am5xy.AxisRendererY.new(root9, { minGridDistance: 20 });
              yRenderer.grid.template.set("location", 1);

              const yAxis = chart.yAxes.push(
                  am5xy.CategoryAxis.new(root9, {
                      maxDeviation: 0,
                      categoryField: "network",
                      renderer: yRenderer,
                      tooltip: am5.Tooltip.new(root9, { themeTags: ["axis"] }),
                  })
              );

              const xAxis = chart.xAxes.push(
                  am5xy.ValueAxis.new(root9, {
                      maxDeviation: 0,
                      min: 0,
                      extraMax: 0.1,
                      renderer: am5xy.AxisRendererX.new(root9, { strokeOpacity: 0.1 }),
                  })
              );

              const serviceItemLabels = {
                  "訓練期間": "訓練期間",
                  "服務期間": "服務期間",
                  "服務期滿": "服務期滿",
              };

              const seriesFields = ["value1", "value2", "value3"];
              seriesFields.forEach((field, index) => {
                  const serviceItem = Object.keys(serviceItemLabels)[index];
                  const series = chart.series.push(
                      am5xy.ColumnSeries.new(root9, {
                          name: serviceItemLabels[serviceItem] || `Value ${index + 1}`,
                          xAxis: xAxis,
                          yAxis: yAxis,
                          valueXField: field, 
                          categoryYField: "network", 
                          stacked: true,
                      })
                  );
                  series.columns.template.setAll({
                      tooltipText: "{valueX}", 
                      tooltipY: am5.percent(10), 
                  });
                  series.columns.template.events.on("pointerover", function (ev) {
                      const column = ev.target;
                      const dataItem = column.dataItem; 
                      if (dataItem) {
                          const valueX = dataItem.get("valueX");
                          console.log("Actual valueX data:", valueX);
                      } else {
                          console.warn("No dataItem found for column:", column);
                      }
                  });
                  series.columns.template.adapters.add("fill", (fill, target) => {
                      return chart.get("colors").getIndex(index);
                  });
                  series.columns.template.adapters.add("stroke", (stroke, target) => {
                      return chart.get("colors").getIndex(index);
                  });
              });
              
              yAxis.data.setAll(Data);
              chart.series.values.forEach((series) => {
                  series.data.setAll(Data);
              });

              const legendContainer = mainContainer.children.push(
                  am5.Container.new(root9, {
                      width: am5.percent(20),
                      height: am5.p100,
                      layout: root9.verticalLayout,
                  })
              );

              const legend = legendContainer.children.push(
                  am5.Legend.new(root9, {
                      centerY: am5.p50,
                      y: am5.p50,
                      layout: root9.verticalLayout,
                  })
              );

              legend.data.setAll(chart.series.values);

              chart.series.values.forEach((series) => {
                  series.appear(1000);
              });
              chart.appear(1000, 100);
              root9._logo.dispose();
          });
        },
        updatePanel1() {
            this.panel1Data = `已選擇: ${this.selectedCounty1}`;
        },
        updatePanel2() {
            this.panel2Data = `已選擇: ${this.selectedCounty2}, 年度範圍: ${this.selectedBeginYear} ～ ${this.selectedEndYear}`;
        },
        updatePanel3() {
            this.panel3Data = `已選擇: ${this.selectedCounty3}`;
        },
        async getDataInitialize() {
            if (!this.selectedCounty1) return;
            try {
                const response = await axios.get(`/RptChart/GetCountryList`, {
                    params: {
                        selectedCounty1: this.selectedCounty1,
                    }
                });

                this.Data = response; 
                // 成功获取数据后再调用初始化
                this.initializeChart(this.Data);
            } catch (error) {
                console.error("Error fetching country data:", error);
                alert("資料載入失敗，請稍後再試");
            }
        },
        async getDataRetentionRate() {
            if (!this.selectedCounty2) return;
            try {
                const response = await axios.get(`/RptChart/GetCountryList`, {
                    params: {
                        selectedCounty2: this.selectedCounty2,
                        selectedBeginYear: this.selectedBeginYear,
                        selectedEndYear: this.selectedEndYear,
                    }
                });

                this.Data = response; 
                // 成功获取数据后再调用初始化
                this.ServiceRetentionRateChart(this.Data);
            } catch (error) {
                console.error("Error fetching country data:", error);
                alert("資料載入失敗，請稍後再試");
            }
        },
        async getDataCardiologists() {
            if (!this.selectedCounty3) return;
            try {
                const response = await axios.get(`/RptChart/GetCountryList`, {
                    params: {
                        selectedCounty3: this.selectedCounty3,
                    }
                });

                this.Data = response; 
                // 成功获取数据后再调用初始化
                this.CardiologistsNumberChart(this.Data);
            } catch (error) {
                console.error("Error fetching country data:", error);
                alert("資料載入失敗，請稍後再試");
            }
        },
    }
    };
</script>