<template>
    <div>
      <!-- 在學、訓練、服務等之醫事人員數 -->
      <div style="display:block;width:120%;height: auto;background-color:seashell;">
        <h2>不同狀態(在學、訓練、服務等)之醫事人員數</h2>
        <span>
            <label for="optCountry" style="font-size:22px;font-weight: bold;">籍屬</label>
            <select id="optCountry" v-model="selectedCountry" @change="getDataSTSCheck" style="font-size:20px;margin-left:10px;width:120px;">
                <option value="" disabled selected>請選擇</option>
                <option v-for="option in countries" :key="option" :value="option">{{ option }}</option>
            </select>
        </span>
        <hr />
        <div id="cPanel1">
          <!-- 在這裡插入根據選擇的籍屬顯示的內容 -->
        </div>
      </div>
      <!-- Studying, Training, and Serving圖表 -->
      <div class="row" style="display:block;width:120%;height: auto;background-color:seashell;">
            <div class="col-sm-12">
                <div id="chartSTSCheck" style="height: 400px; width: 1500px;"></div>
            </div>
       </div>
  
      <hr />
  
      <!-- 服務期滿留任率 -->
      <div style="display:block;width:120%;height:auto;background-color:ivory;">
        <h2>服務期滿留任率</h2>
        <span>
          <label for="optYearCountry" style="font-size:22px;font-weight: bold;">籍屬</label>
          <select id="optYearCountry" v-model="selectedYearCountry" @change="getDataRetentionRate" style="font-size:20px;margin-left:10px;width:120px;">
            <option value="" disabled selected>請選擇</option>
            <option v-for="option in countries" :key="option" :value="option">{{ option }}</option>
          </select>
  
          <label for="optBeginYearCountry" style="font-size:22px;font-weight: bold;;margin-left:50px;">年度</label>
          <select id="optBeginYearCountry" v-model="selectedBeginYear" @change="getDataRetentionRate" style="font-size:20px;margin-left:10px;width:120px;">
            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
          </select>
  
          <label for="optEndYearCountry" style="font-size:22px;font-weight: bold;;width:5px;">～</label>
          <select id="optEndYearCountry" v-model="selectedEndYear" @change="getDataRetentionRate" style="font-size:20px;margin-left:10px;width:120px;">
            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
          </select>
        </span>
        <hr />
        <div id="cPanel2">
          <!-- 在這裡插入根據選擇的年度和籍屬顯示的內容 -->
        </div>
      </div>
      <div class="row" style="display:block;width:120%;height: auto;background-color:seashell;">
            <div class="col-sm-12">
                <div id="RetentionRate" style="height: 400px; width: 1500px;"></div>
            </div>
       </div>
    </div>
  </template>
  

  
  <style scoped>
  /* 加入樣式，這部分根據你的需求來設計 */
  </style>

<script>
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

    export default {
    data() {
        return {
            // 籍屬選項
            countries: ["原住民", "一般生", "離島", "偏鄉"],
            
            // 年度選項
            years: ["90", "91", "92", "93", "94", "95", "96", "97", "98", "99", "100", "101", "102", "103", "104", "105", "106", "107", "108", "109", "110"],
    
            // 綁定的值
            selectedCountry: '',
            selectedYearCountry: '',
            selectedBeginYear: '90',
            selectedEndYear: '110'
        };
    },
    name: "RptByCounty", 
    mounted() {
        this.$nextTick(() => {
            //this.initializeSTSCheck();
            //this.initializeRetentionRate();
        });
    },
    methods: {
        getSeriesItem(category, series) {
            if (!series || !series.dataItems || series.dataItems.length === 0) {
                console.error("Series 或 dataItems 不存在或尚未初始化。");
                return null;
            }

            // 開始遍歷 series.dataItems
            for (let i = 0; i < series.dataItems.length; i++) {
                const dataItem = series.dataItems[i];
                if (dataItem.get("categoryY") === category) {
                    return dataItem;
                }
            }
            return null; // 如果找不到匹配的項目，返回 null
        },
        initializeSTSCheck(countryData) {
            const self = this;

            am5.ready(function () {
                // 清理舊圖表實例
                if (self.stsChartRoot) {
                    self.stsChartRoot.dispose();
                }

                const root9 = am5.Root.new("chartSTSCheck");
                self.stsChartRoot = root9;

                root9.setThemes([am5themes_Animated.new(root9)]);

                const mainContainer = root9.container.children.push(
                    am5.Container.new(root9, {
                        layout: root9.horizontalLayout,
                        width: am5.p100,
                        height: am5.p100,
                    })
                );

                const chartContainer = mainContainer.children.push(
                    am5.Container.new(root9, {
                        width: am5.percent(80),
                        height: am5.p100,
                    })
                );

                const chart = chartContainer.children.push(
                    am5xy.XYChart.new(root9, {
                        panX: false,
                        panY: false,
                        wheelX: "none",
                        wheelY: "none",
                    })
                );

                chart.get("colors").set("colors", [
                    am5.color(0x1d728c),
                    am5.color(0x29a19c),
                    am5.color(0xf5a623),
                    am5.color(0xff6f61),
                    am5.color(0x9c27b0),
                ]);
                chart.zoomOutButton.set("forceHidden", true);

                const yRenderer = am5xy.AxisRendererY.new(root9, { minGridDistance: 20 });
                yRenderer.grid.template.set("location", 1);

                const yAxis = chart.yAxes.push(
                    am5xy.CategoryAxis.new(root9, {
                        maxDeviation: 0,
                        categoryField: "network",
                        renderer: yRenderer,
                        tooltip: am5.Tooltip.new(root9, { themeTags: ["axis"] }),
                    })
                );

                const xAxis = chart.xAxes.push(
                    am5xy.ValueAxis.new(root9, {
                        maxDeviation: 0,
                        min: 0,
                        extraMax: 0.1,
                        renderer: am5xy.AxisRendererX.new(root9, { strokeOpacity: 0.1 }),
                    })
                );

                const serviceItemLabels = {
                    "在學期間": "在學期間",
                    "訓練期間": "訓練期間",
                    "服務期間": "服務期間",
                    "服務期滿": "服務期滿",
                    "其他": "其他(履約終止、退學、畢業)",
                };

                const seriesFields = ["value1", "value2", "value3", "value4", "value5"];
                seriesFields.forEach((field, index) => {
                    const serviceItem = Object.keys(serviceItemLabels)[index];
                    const series = chart.series.push(
                        am5xy.ColumnSeries.new(root9, {
                            name: serviceItemLabels[serviceItem] || `Value ${index + 1}`,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueXField: field, // 数据源中用于X轴值的字段
                            categoryYField: "network", // 数据源中用于分类的字段
                            stacked: true,
                        })
                    );
                    series.columns.template.setAll({
                        tooltipText: "{valueX}", // 初始 Tooltip 内容，可根据需要设置占位符
                        tooltipY: am5.percent(10), // 调整 Tooltip 的垂直位置
                    });
                    series.columns.template.events.on("pointerover", function (ev) {
                        const column = ev.target;
                        const dataItem = column.dataItem; 
                        if (dataItem) {
                            const valueX = dataItem.get("valueX");
                            console.log("Actual valueX data:", valueX);
                        } else {
                            console.warn("No dataItem found for column:", column);
                        }
                    });
                    series.columns.template.adapters.add("fill", (fill, target) => {
                        return chart.get("colors").getIndex(index);
                    });
                    series.columns.template.adapters.add("stroke", (stroke, target) => {
                        return chart.get("colors").getIndex(index);
                    });
                });
                
                yAxis.data.setAll(countryData);
                chart.series.values.forEach((series) => {
                    series.data.setAll(countryData);
                });

                const legendContainer = mainContainer.children.push(
                    am5.Container.new(root9, {
                        width: am5.percent(20),
                        height: am5.p100,
                        layout: root9.verticalLayout,
                    })
                );

                const legend = legendContainer.children.push(
                    am5.Legend.new(root9, {
                        centerY: am5.p50,
                        y: am5.p50,
                        layout: root9.verticalLayout,
                    })
                );

                legend.data.setAll(chart.series.values);

                chart.series.values.forEach((series) => {
                    series.appear(1000);
                });
                chart.appear(1000, 100);
                root9._logo.dispose();
            });
        },

        initializeRetentionRate(rateData) {
            const self = this;
            // 如果存在旧的 root 实例，则销毁它
            if (this.chartRoot) {
                this.chartRoot.dispose();
            }
            am5.ready(function () {
                var root9 = am5.Root.new("RetentionRate");
                self.chartRoot = root9;
                root9.setThemes([am5themes_Animated.new(root9)]);

                // 主容器
                var mainContainer = root9.container.children.push(
                    am5.Container.new(root9, {
                        layout: root9.horizontalLayout,
                        width: am5.p100,
                        height: am5.p100,
                    })
                );

                // 图表容器
                var chartContainer = mainContainer.children.push(
                    am5.Container.new(root9, {
                        width: am5.percent(80),
                        height: am5.p100,
                    })
                );

                // 图表
                var chart = chartContainer.children.push(
                    am5xy.XYChart.new(root9, {
                        panX: false,
                        panY: false,
                        wheelX: "none",
                        wheelY: "none",
                    })
                );

                chart.zoomOutButton.set("forceHidden", true);

                // Y 轴（数值轴）
                var yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root9, {
                        renderer: am5xy.AxisRendererY.new(root9, {}),
                        min: 0,
                    })
                );

                // X 轴（类别轴）
                var xAxis = chart.xAxes.push(
                    am5xy.CategoryAxis.new(root9, {
                        categoryField: "category", // 定义类别
                        renderer: am5xy.AxisRendererX.new(root9, {
                            minGridDistance: 30, // 减小网格间距，避免过大的间隙
                            cellPadding: 0,      // 去除类别标签与柱状图之间的空隙
                        }),
                        paddingRight: 0,  // 减小右侧空白区域
                    })
                );
                
                // 数据
                var data = rateData;
                //{ category: "Jan", columnValue: 40, columnValue2: 42, lineValue: 35 },
                xAxis.data.setAll(data);

                var columnSeries1 = chart.series.push(
                    am5xy.ColumnSeries.new(root9, {
                        name: "服務期滿人數",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "columnValue",
                        categoryXField: "category",
                        tooltip: am5.Tooltip.new(root9, {
                            labelText: "{category}: {valueY}",
                        }),
                        clustered: true, // 保持为true，避免重叠
                    })
                );

                columnSeries1.columns.template.setAll({
                    width: am5.percent(40),  // 设置柱状图宽度
                    innerPaddingLeft: 0,     // 去除左侧内间距
                    innerPaddingRight: 0,    // 去除右侧内间距
                    fill: am5.color(0x1d728c),
                    stroke: am5.color(0x1d728c),
                    strokeOpacity: 0,
                    tooltipText: "{valueY}", // 初始 Tooltip 内容，可根据需要设置占位符
                    tooltipY: am5.percent(10), // 调整 Tooltip 的垂直位置
                });
                

                columnSeries1.data.setAll(data);

                // 第二组柱状图 (columnValue2)
                var columnSeries2 = chart.series.push(
                    am5xy.ColumnSeries.new(root9, {
                        name: "留任人數",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "columnValue2",
                        categoryXField: "category",
                        tooltip: am5.Tooltip.new(root9, {
                            labelText: "{category}: {valueY}",
                        }),
                        clustered: true, // 保持为true，避免重叠
                    })
                );

                columnSeries2.columns.template.setAll({
                    width: am5.percent(40),  // 设置柱状图宽度
                    innerPaddingLeft: 0,     // 去除左侧内间距
                    innerPaddingRight: 0,    // 去除右侧内间距
                    fill: am5.color(0xff7f0e),
                    stroke: am5.color(0xff7f0e),
                    strokeOpacity: 0,
                    tooltipText: "{valueY}", // 初始 Tooltip 内容，可根据需要设置占位符
                    tooltipY: am5.percent(10), // 调整 Tooltip 的垂直位置
                });

                // 手动调整第二个柱状图的位置，避免重叠
                columnSeries2.columns.template.setAll({
                    x: am5.percent(60),  // 将第二组柱状图稍微向右偏移
                });

                columnSeries2.data.setAll(data);

                // 折线图
                var lineSeries = chart.series.push(
                    am5xy.LineSeries.new(root9, {
                        name: "留任率",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "lineValue",
                        categoryXField: "category",
                        tooltip: am5.Tooltip.new(root9, {
                            labelText: "{category}: {valueY}",
                        }),
                    })
                );

                // 添加点标记
                lineSeries.bullets.push(function () {
                    return am5.Bullet.new(root9, {
                        sprite: am5.Circle.new(root9, {
                            radius: 5,
                            fill: lineSeries.get("fill"),
                            tooltipText: "{valueY}%", // Tooltip 显示的数据字段
                            tooltipY: am5.percent(10), // 调整 Tooltip 的位置
                        }),
                    });
                });
                lineSeries.data.setAll(data);

                // 图例容器
                var legendContainer = mainContainer.children.push(
                    am5.Container.new(root9, {
                        width: am5.percent(20),
                        height: am5.p100,
                        layout: root9.verticalLayout,
                    })
                );

                // 创建图例
                var legend = legendContainer.children.push(
                    am5.Legend.new(root9, {
                        centerY: am5.p50,
                        y: am5.p50,
                        layout: root9.verticalLayout,
                    })
                );

                legend.data.setAll(chart.series.values);

                columnSeries1.appear(1000);
                columnSeries2.appear(1000);
                lineSeries.appear(1000);
                chart.appear(1000, 100);
                root9._logo.dispose();
            });
        },

        async getDataSTSCheck() {
            if (!this.selectedCountry) return;
            try {
                const response = await axios.get(`/RptChart/GetSTSList`, {
                    params: {
                        selectedCountry: this.selectedCountry
                    }
                });

                this.countryData = response; 
                // 成功获取数据后再调用初始化
                this.initializeSTSCheck(this.countryData);
            } catch (error) {
                console.error("Error fetching country data:", error);
                alert("資料載入失敗，請稍後再試");
            }
        },
        async getDataRetentionRate() {
            if (!this.selectedYearCountry) return;

            try {
                const response = await axios.get(`/RptChart/GetRateList`, {
                    params: {
                        selectedYearCountry: this.selectedYearCountry,
                        selectedBeginYear: this.selectedBeginYear,
                        selectedEndYear: this.selectedEndYear,
                    }
                });

                // 確保從 response 中提取正確的數據
                this.rateData = response;
                console.log("Rate data loaded:", this.rateData);

                // 成功获取数据后初始化
                this.initializeRetentionRate(this.rateData);
            } catch (error) {
                console.error("Error fetching rate data:", error.response || error.message);
                alert("資料載入失敗，請稍後再試");
            }
        },
        updatePanel1() {
            this.panel1Data = `已選擇: ${this.selectedCounty1}`;
        },
        updatePanel2() {
            this.panel2Data = `已選擇: ${this.selectedCounty2}, 年度範圍: ${this.selectedBeginYear} ～ ${this.selectedEndYear}`;
        },
        updatePanel3() {
            this.panel3Data = `已選擇: ${this.selectedCounty3}`;
        },
    },
    watch: {
        '$route'(to, from) {
            this.$nextTick(() => {
                // 在路由切换后确保 DOM 已经更新
                this.initializeChart();
            });
        }
    },
    };
</script>

<style scoped>
    .amcharts-tooltip {
        z-index: 1000 !important; /* 确保 Tooltip 层级最高 */
        display: block !important; /* 确保 Tooltip 不会被隐藏 */
    }
</style>
