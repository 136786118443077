<template>
  <b-modal
    @shown="updateItem"
    :title="
      `公費生歷程資料 - 編號:${item.code} 身分證號:${item.id} 姓名:${item.name}`
    "
    id="studentProgress"
    size="xl"
    @hide="doSometing"
  >
    <template #modal-title
      >公費生歷程資料 -
      <span class="mr-3">編號: {{ item.code }}</span>
      <span class="mr-3">身分證號: {{ item.id }}</span>
      <span>姓名: {{ item.name }}</span></template
    >
    <b-container fluid>
      <div>
        <b-card-group deck>
          <b-card
            :bg-variant="variants[0]"
            :header="localStatusObject[1]"
            class="contentlocalStatusObject1"
            @click="tab = 0"
          >
            <b-card-text>
              <div v-if="!personData1.length" style="text-align:center">暫無資料</div>
              <div
                v-for="(item, i) in personData1"
                :key="`tab1personData${i}`"
                :title="`${item.content}，${item.DateStr}`"
              >
                {{ item.content || "" }}，{{ item.DateStr || "" }}
              </div>
            </b-card-text>
          </b-card>
          <div class="icon"><i class="fas fa-caret-right"></i></div>
          <b-card
            :bg-variant="variants[1]"
            text-variant="white"
            :header="`${Boolean(item.cat == '護理') ? '護理師國考' : localStatusObject[8]}`"
            class="content"
            @click="tab = 1"
          >
            <b-card-text>
              <div v-if="!personData2.length" style="text-align:center">暫無資料</div>
              <div
                v-for="(item, i) in personData2"
                :key="`tab2personData${i}`"
                :title="`${item.time}，${item.name}`"
              >
                {{ item.name || "" }}，{{ item.time || "" }}
              </div>
            </b-card-text>
          </b-card>
          <div class="icon" v-if="Boolean(item.cat == '醫師') || Boolean(item.cat == '牙醫')">
            <i class="fas fa-caret-right"></i>
          </div>
          <b-card
            v-if="Boolean(item.cat == '醫師') || Boolean(item.cat == '牙醫')"
            :bg-variant="variants[2]"
            text-variant="white"
            :header="localStatusObject[3]"
            class="content"
            @click="tab = 2"
          >
            <b-card-text style="text-align:left">
              <div v-if="!personData3.length" style="text-align:center">暫無資料</div>
              <div v-else><span v-show="personData3">訓練期間 <br /></span>{{ personData3 }}</div>
            </b-card-text>
            <b-card-text style="text-align:center">
                <div v-if="!personData4.length" style="text-align:center">暫無資料</div>
                <div v-else><span v-show="personData4">專科訓練/其他醫事期間 <br /></span>{{ personData4 }}</div>
              </b-card-text>
          </b-card>
          <div class="icon" v-if="Boolean(+item.type == 1 || +item.type == 3)">
            <i class="fas fa-caret-right"></i>
          </div>
          <b-card
            v-if="Boolean(+item.type == 1 || +item.type == 3)"
            :bg-variant="variants[3]"
            text-variant="white"
            :header="localStatusObject[4]"
            class="content"
            @click="tab = 4"
          >
            <b-card-text style="text-align:center">
              <div v-if="!personData5.length" style="text-align:center">暫無資料</div>
              <div v-else><span v-show="personData5">分發服務期間 <br /></span>{{ personData5 }}</div></b-card-text
            >
          </b-card>
          <div class="icon"><i class="fas fa-caret-right"></i></div>
          <b-card
            :bg-variant="variants[4]"
            text-variant="white"
            :header="localStatusObject[5]"
            class="content"
            @click="tab = 3"
          >
            <b-card-text style="text-align:center">
              <div v-if="!personData6.length" style="text-align:center">暫無資料</div>
              <div v-else><span v-show="personData6">服務期滿期間 <br /></span>{{ personData6 }}</div>
            </b-card-text>
          </b-card>
          <div class="icon"><i class="fas fa-caret-right"></i></div>
          <b-card
            :bg-variant="variants[5]"
            text-variant="white"
            :header="localStatusObject[6]"
            class="content"
            @click="tab = 5"
          >
            <b-card-text style="text-align:center">
              <!--尚無對應功能，先保留原程式-->
              <!--<div v-if="!personData6.length" style="text-align:center">暫無資料</div>
              <div v-else><span v-show="personData6">展緩期間 <br /></span>{{ personData6 }}</div>-->
              <div><span>展緩期間 <br /></span>{{ "尚未設定" }}</div>
            </b-card-text>
          </b-card>
          <div class="icon"><i class="fas fa-caret-right"></i></div>
          <b-card
            :bg-variant="variants[6]"
            text-variant="white"
            :header="localStatusObject[7]"
            class="content"
            @click="tab = 6"
          >
            <b-card-text style="text-align:center">
              <div v-if="!personData7" style="text-align:center">暫無資料</div>
              <div v-else><span v-show="personData7">履約終止 <br /></span>{{ personData7 }}</div>
            </b-card-text>
          </b-card>
        </b-card-group>
      </div>
      <component :is="comps[tab]"/>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('studentProgress')"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { yearsObject } from "@/constant.js";
import { store } from "@/store/global.js";
import moment from "moment";
import ProOne from "@/components/systemmanage/progressTable/ProOne";
import ProTwo from "@/components/systemmanage/progressTable/ProTwo";
import ProThree from "@/components/systemmanage/progressTable/ProThree";
import ProFour from "@/components/systemmanage/progressTable/ProFour";
import ProFive from "@/components/systemmanage/progressTable/ProFive";
import ProSix from "@/components/systemmanage/progressTable/ProSix";
import ProSeven from "@/components/systemmanage/progressTable/ProSeven";
import {   localStatusObject,   allRuralServiceObject } from "@/constant.js";

export default {
  name: "EditPersonItem",
  data() {
    return {
      item: { ...store.studentProgressInfo },//個人資料
      localStatusObject,
      variants: [
        "light",
        "warning",
        "info",
        "danger",
        "secondary",
        "primary",
        "success",
      ],
      tab: 0,
      personData1: [],
      personData2: [],
      personData3: "",
      personData4: "",
      personData5: "",
      personData6: "",
      personData7: "",
      allTimeData: {},
      comps:['ProOne', 'ProTwo', 'ProThree', 'ProFour', 'ProFive', 'ProSix', 'ProSeven']
    };
  },
  computed: {},
  components: { ProOne, ProTwo, ProThree, ProFour, ProFive, ProSix, ProSeven },
  methods: {
    async setTabOneContent() {
      const isStudent = this.item.status == "在學中" ? true : false;
      const id = store.studentProgressInfo.id;
      const url = `StudentInfo/GetInSchool?Identifier=${id}`;
      try {
        let res = await window.axios.get(url);
        let arr = [];

        if (isStudent) {
          arr = res.Contents.filter((s) => s.content.includes("入學"));
        } else {
          arr = res.Contents.sort(function(a, b) {
            if (a.content > b.content) return -1;
            if (a.content < b.content) return 1;
          });
        }
        this.personData1 = [];
        this.personData1 = [...arr];
      } catch (e) {
        // this.$bvToast.toast(e + ``, {
        //   title: "系統資訊",
        //   autoHideDelay: 5000,
        //   variant: "danger",
        // });
      }
    },
    async setTtabTwoContent() {
      const id = store.studentProgressInfo.id;
      // const url = `StudentInfo/GetNationalExam?Identifier=${id}`;
      const url = `StudentInfo/GetNationalExamDetail?Identifier=${id}`;
      try {
        const res = await window.axios.get(url);
        this.personData2 = [];
        if (res) {
          let arr1 = res.License ? [...res.License] : [];
          let arr2 = res.ExpertLicense ? [...res.ExpertLicense] : [];
          arr1 = arr1.map((s) => ({
            name: s.ref_name,
            time: s.cht_cet_approve_date.replace(/\./g, "-"),
          }));
          arr2 = arr2.map((s) => ({
            name: s.sdw_name,
            time: s.cht_spc_lic_edate.replace(/\./g, "-"),
          }));
          this.personData2 = [...arr1, ...arr2];
        }
      } catch (e) {}
    },
    async setTtabThreeContent() {
      if (Boolean(this.allTimeData.PGY_Dates)) {
        this.personData3 = this.allTimeData.PGY_Dates.includes("~")
          ? `起${this.allTimeData.PGY_Dates.split("~")[0].replace(
              /\./g,
              "-"
            )}~訖${this.allTimeData.PGY_Dates.split("~")[1].replace(
              /\./g,
              "-"
            )}`
          : "尚未設定";
      } else {
        this.personData3 = "尚未設定";
      }
      // this.personData3 = `起:${time1} ~ 訖:${time2}`;
      // const id = store.studentProgressInfo.id;
      // const url = `StudentInfo/GetTrainingHospital?identifier=${id}&category=P`;
      // try {
      //   const res = await window.axios.get(url);
      //   this.personData3 = "";
      //   if (res.length) {
      //     const time2 = res[0].YearMonth.replace(/\//g, "-");
      //     const time1 = res[res.length - 1].YearMonth.replace(/\//g, "-");
      //     this.personData3 = `起:${time1} ~ 訖:${time2}`;
      //   }
      // } catch (e) {}
    },
    async setTtabFourContent() {
      if (Boolean(this.allTimeData.Expert_Dates)) {
        this.personData4 = this.allTimeData.Expert_Dates.includes("~")
          ? `起${this.allTimeData.Expert_Dates.split("~")[0].replace(
              /\./g,
              "-"
            )}~訖${this.allTimeData.Expert_Dates.split("~")[1].replace(
              /\./g,
              "-"
            )}`
          : "尚未設定";
      } else {
        this.personData4 = "尚未設定";
      }
      // this.personData4 = `起:${time1} ~ 訖:${time2}`;
      // const id = store.studentProgressInfo.id;
      // const url = `StudentInfo/GetTrainingHospital?identifier=${id}&category=E`;
      // try {
      //   const res = await window.axios.get(url);
      //   this.personData4 = "";
      //   if (res.length) {
      //     const time2 = res[0].YearMonth.replace(/\//g, "-");
      //     const time1 = res[res.length - 1].YearMonth.replace(/\//g, "-");
      //     this.personData4 = `起:${time1} ~ 訖:${time2}`;
      //   }
      // } catch (e) {}
    },
    async setTtabFiveContent() {
      // const id = store.studentProgressInfo.id;
      // const url = `StudentInfo/GetServiceHistory?identifier=${id}&category=S`;
      // try {
      //   const res = await window.axios.get(url);
      //   this.personData5 = "";
      //   if (res.length) {
      //     const time2 = res[0].StartDate ? this.$twDate(res[0].StartDate) : "";
      //     const time1 = res[res.length - 1].EndDate
      //       ? this.$twDate(res[res.length - 1].EndDate)
      //       : "";

      //     this.personData5 = `起:${time1} ~ 訖:${time2}`;
      //   }
      // } catch (e) {}
      const id = store.studentProgressInfo.id;
      const url = `StudentTraining/Get?identifier=${id}&category=S`;
      try {
        const res = await window.axios.get(url);
        this.personData5 = "尚未設定";
        if (res.length) {
          // const time2 = res.length
          //   ? this.$twDate(moment(res[0].EndDate).format("YYYY-MM-DD"))
          //   : null;
          const time2 = !res.length
            ? ""
            : Boolean(res[0].EndDate)
            ? this.$twDate(moment(res[0].EndDate).format("YYYY-MM-DD"))
            : "";
          // const time1 = res.length
          //   ? this.$twDate(moment(res[0].StartDate).format("YYYY-MM-DD"))
          //   : null;
          const time1 = !res.length
            ? ""
            : Boolean(res[0].StartDate)
            ? this.$twDate(moment(res[0].StartDate).format("YYYY-MM-DD"))
            : "";

          this.personData5 =
            !Boolean(time1) && !Boolean(time2)
              ? "尚未設定"
              : `起:${time1} ~ 訖:${time2}`;
        }
      } catch (e) {}
    },
    async setTtabSixContent() {
      // const id = store.studentProgressInfo.id;
      // const url = `StudentInfo/GetServiceHistory?identifier=${id}&category=SF`;
      // try {
      //   const res = await window.axios.get(url);
      //   this.personData6 = "";
      //   if (res.length) {
      //     const time2 = res[0].StartDate ? this.$twDate(res[0].StartDate) : "";
      //     const time1 = res[res.length - 1].EndDate
      //       ? this.$twDate(res[res.length - 1].EndDate)
      //       : "";

      //     this.personData6 = `起:${time1} ~ 訖:${time2}`;
      //   }
      // } catch (e) {}
      const id = store.studentProgressInfo.id;
      const url = `StudentTraining/Get?identifier=${id}&category=S`;
      try {
        const res = await window.axios.get(url);
        this.personData6 = "尚未設定";
        if (res.length) {
          const time2 = !res.length
            ? ""
            : Boolean(res[0].EndDate)
            ? this.$twDate(
                moment(res[0].EndDate)
                  .add(1, "days")
                  .format("YYYY-MM-DD")
              )
            : "";

          const time1 = this.$twDate(moment(Date.now()).format("YYYY-MM-DD"));
          this.personData6 =
            !Boolean(time1) && !Boolean(time2)
              ? "尚未設定"
              : `起:${time2} ~ 訖:${time1}`;
          if (Boolean(time1) && Boolean(time2)) {
            if (Date.parse(res[0].EndDate) > Date.now()) {
              this.personData6 = `起:${time2} ~ 訖:`;
            }
          }
        }
        if (this.item.servicenow == "死亡" || this.item.servicenow == "退休") {
          this.personData6 = this.item.servicenow;
        }
      } catch (e) {}
    },
    async setTtabSevenContent() {
      const id = store.studentProgressInfo.id;
      const url = `StudentInfo/GetStudent?identifier=${id}`;
      try {
        const res = await window.axios.get(url);
        this.personData7 = "尚未設定";  
        if(res)      
        {
          this.personData7 = res.ServiceItem;
        }
        
      } catch (e) {}
    },
    async getAllTimeData() {
      const id = store.studentProgressInfo.id;
      const url = `StudentInfo/GetStudentService?$filter=Identifier eq '${id}'`;

      const { Items } = await window.axios.get(url);
      this.allTimeData = Items.length ? { ...Items[0] } : {};
    },
    async updateItem() {
      this.item = {};
      this.allTimeData = {};
      this.item = { ...store.studentProgressInfo };
      await this.getAllTimeData();
      this.setTabOneContent();
      this.setTtabTwoContent();
      this.setTtabThreeContent();
      this.setTtabFourContent();
      this.setTtabFiveContent();
      this.setTtabSixContent();
      this.setTtabSevenContent();
    },
    doSometing() {
      this.$bvModal.hide("studentProgress");
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 150px;
  background: #6a6b6bf0;
  color: #fff;
}
div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 300px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #0379fd;
  color: #ffffff;
}
.code-dtc {
  display: grid;
  grid-template-columns: 60px minmax(400px, 525px);
}
.input-group {
  margin-bottom: 10px;
}
.main-grid-dtc {
  display: grid;
  grid-template-columns: 350px 350px 1fr;
  column-gap: 5px;
}
.main-grid3-dtc {
  display: grid;
  grid-template-columns: 350px 350px 1fr;
}

::v-deep #studentProgress > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.form-control[readonly] {
  background-color: #ffffff;
}
::v-deep .modal-content {
  margin-left: -335px;
  width: 160%;
}
::v-deep div.card.content {
  div.card-header {
    text-align: center;
    // color: #333;
    // font-weight: 600;
    font-size: 18px;
    text-shadow: -1px 0 #050505, 0px 1px #0f0f0f, 1px 0px #0f0f0f91,
      2px 0px #0f0f0f1f;
  }
  div.card-body {
    p {
      font-size: 15px;
      text-align: left;
    }
    div {
      font-size: 15px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
::v-deep div.card.contentlocalStatusObject1 {
  div.card-header {
    text-align: center;
    // color: #333;
    // font-weight: 600;
    font-size: 18px;
    text-shadow: -1px 0 #cfcece, 0px 1px #f3f3f3, 1px 0px #ffffff91,
      2px 0px #0f0f0f1f;
  }
  div.card-body {
    p {
      font-size: 15px;
      text-align: left;
    }
    div {
      font-size: 15px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
::v-deepdiv.card.content.car-1.bg-light > div.card-header {
  text-shadow: none;
}

::v-deep .card-deck .card {
  &:hover {
    filter: brightness(84%);
    cursor: pointer;
  }
  margin-right: 7px;
  margin-left: 7px;
}
.icon {
  color: #f36c23;
  margin-top: 64px;
  font-size: 18px;
}
</style>
